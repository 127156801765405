<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">考核指标得分管理</h2>
        <div>
          <el-form class="twoForm" :inline="true">
            <el-form-item>
              <el-date-picker
                  v-model="startTime"
                  type="month"
                  format="yyyy 年 MM 月"
                  value-format="yyyy-MM"
                  @change="changestartTime"
                  placeholder="选择时间">
              </el-date-picker>
            </el-form-item>
          </el-form>

        </div>
        <el-table
            :data="DeplisttableData"
            border
            center
        >
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="month" label="月份" width="90" align="center">
          </el-table-column>
          <el-table-column prop="name" label="指标名称" align="center" width="300px">
          </el-table-column>
          <el-table-column prop="weightScore"  label="权重分值" align="center" width="150px">
          </el-table-column>
          <el-table-column prop="cardinal" label="基数" align="center" width="150px">
            <template style="display: flex" slot-scope="scope">
              <div v-if="(scope.row.id == 1 || scope.row.id == 4 || scope.row.id == 6) && scope.row.cardinal">{{scope.row.cardinal+'%'}}</div>
              <div v-else>{{scope.row.cardinal}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="evaluate" label="评价办法" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="baseMonth" label="本月" align="center" width="150px">
            <template style="display: flex" slot-scope="scope">
              <div v-if="(scope.row.id == 1 || scope.row.id == 4 || scope.row.id == 6) && scope.row.baseMonth">{{scope.row.baseMonth+'%'}}</div>
              <div v-else>{{scope.row.baseMonth}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="scoreMonth"  label="本月得分" align="center" width="150px">
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import _qs from "qs";

export default {
  data() {
    return {
      // 表格数据
      DeplisttableData: [],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      departmentName:sessionStorage.getItem("departmentName"),
      startTime:''
    };
  },
  created() {
    this.getDateTime()
  },
  methods: {
    // 获取指标列表
    async indexList() {
      let data = _qs.stringify({
        departmentId:window.sessionStorage.getItem("departmentId"),
        month:this.startTime
      });

      let { data: res } = await this.$axios.indexMonthList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.DeplisttableData = res.rows;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async getDateTime(){
      let { data: res } = await this.$axios.getDateTime();
      // console.log(res);
      this.startTime=res
      this.indexList();
    },
    changestartTime(val){
      this.startTime=val
      this.indexList();
    }
  }
};
</script>

<style lang="less" scoped>
.tabBig{
  width: 500px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  /deep/ .el-input__inner{
    border:none!important;
  }
  .line{
    height: 40px;
    border: 1px solid #ccc;
  }
  .boxTwo,.boxOne{
    width: 50%;
  }
}

/deep/ .el-form-item.hosform{
  margin-bottom: 0px!important;
}

.twoForm {
  float: right;
  display: flex;
}

/deep/ .el-select.xiangType .el-input--suffix {
  width: 150px !important;
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}

.btnSize button{
  width: 120px;
  margin-top: 20px;
}

/deep/ .el-input-number__decrease,/deep/ .el-input-number__increase{
  display: none;
}

.checRev /deep/ .el-checkbox{
  margin-bottom: 20px;
}

/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}

/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #ffffff;
}


//css
.evaluate {
  width: 1000px;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
</style>
